export const getContentfulData = async (query) => {
  try {
    const res = await fetch(
      `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_SPACE_ID}/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authenticate the request
          Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
        },

        body: JSON.stringify({ query }),
      }
    );
    const data = await res.json();
    return data;
  } catch (error) {
    console.log("----- Error while fetching contentful data ----");
  }
};
