import { React } from "react";

import footerlogo from "./assets/footlogo.png";

const Footercontact = () => {
  return (
    <div className="footer">
      <div className="footer-logo">
        <img src={footerlogo} />
      </div>
      <h5 className="footer-brandtext">ID Theory</h5>
      <p className="text-center footer-text">
        All Rights Reserved. Not a Public Solicitation.
      </p>
    </div>
  );
};

export default Footercontact;
