import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import logo from "./svgs/logo.svg";
import NavbarPages from "../NavbarPages";
import { motion, useAnimation } from "framer-motion";
import Widenav from "../Widenav";
import ScrollToTopOnMount from "./ScrollToTopOnMount";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";

function FadeInWhenVisible({ children }) {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: 0.5, delay: 0.2 }}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
    >
      {children}
    </motion.div>
  );
}

export default function Home() {
  const history = useHistory();

  const handleCircleClick = (tabIndex) => {
    history.push("/portfolio", { activeTab: tabIndex });
  };

  useEffect(() => {
    const circles = document.querySelectorAll(".svg-circle");

    circles.forEach((circle) => {
      // This color will be used when hovering over the circle
      const hoverColor = circle.getAttribute("data-hover-color");

      // Function to handle mouse over event
      const handleMouseOver = () => {
        circle.style.stroke = hoverColor;
        document
          .querySelectorAll(
            `.svg-label[data-for="${circle.id}"], .svg-label-mid[data-for="${circle.id}"]`
          )
          .forEach((text) => {
            text.style.fill = hoverColor;
          });
      };

      // Function to handle mouse out event
      const handleMouseOut = () => {
        circle.style.stroke = "#c3c3c3"; // reset to default stroke color
        document
          .querySelectorAll(
            `.svg-label[data-for="${circle.id}"], .svg-label-mid[data-for="${circle.id}"]`
          )
          .forEach((text) => {
            text.style.fill = ""; // reset to default text color, adjust if a specific color is needed
          });
      };

      // Attach the event listeners
      circle.addEventListener("mouseover", handleMouseOver);
      circle.addEventListener("mouseout", handleMouseOut);

      // Return a cleanup function that removes the event listeners
      return () => {
        circle.removeEventListener("mouseover", handleMouseOver);
        circle.removeEventListener("mouseout", handleMouseOut);
      };
    });
  }, []);

  return (
    <div className="home-overflow">
      <ScrollToTopOnMount />
      <motion.div className="flex flex-col home-container...">
        <NavbarPages />
        <motion.div
          className=""
          transition={{ duration: 0.5 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <div className="hero ">
            <div className="hero-inner">
              <img className="main-logo" src={logo} alt="main logo" />

              <h1 className="main-header">ID THEORY</h1>
              <h4 className="mt-5 text-center sub-header tam-bleed">
                a crypto fund investing at the bleeding edge{" "}
              </h4>

              {
                <div className="svg-container">
                  <svg
                    viewBox="0 0 1000 780"
                    width="1000"
                    height="780"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="494"
                      cy="260"
                      r="130"
                      id="top-circle"
                      class="svg-circle"
                      stroke="#c3c3c3"
                      stroke-width="4"
                      fill="transparent"
                      data-hover-color="#8489aa"
                      onClick={() => handleCircleClick(2)}
                    />
                    <circle
                      cx="385"
                      cy="455"
                      r="130"
                      id="left-circle"
                      class="svg-circle"
                      stroke="#c3c3c3"
                      stroke-width="4"
                      fill="transparent"
                      data-hover-color="#e17e52"
                      onClick={() => handleCircleClick(4)}
                    />
                    <circle
                      cx="608.5"
                      cy="455"
                      r="130"
                      id="right-circle"
                      class="svg-circle"
                      stroke="#c3c3c3"
                      stroke-width="4"
                      fill="transparent"
                      data-hover-color="#91ccd3"
                      onClick={() => handleCircleClick(3)}
                    />
                    <text
                      x="490"
                      y="100"
                      class="svg-label"
                      data-for="top-circle" // Relate this text to the top circle
                      text-anchor="middle"
                    >
                      <tspan fill="#8489aa">
                        Autonomous agents will rule the world
                      </tspan>
                    </text>

                    <text
                      x="494"
                      y="273"
                      class="svg-label-mid"
                      text-anchor="middle"
                      data-for="top-circle"
                    >
                      DeAI
                    </text>

                    <text
                      x="376.5"
                      y="461.5"
                      class="svg-label-mid"
                      text-anchor="middle"
                      data-for="left-circle"
                    >
                      DeFi
                    </text>
                    <text
                      x="250"
                      y="635"
                      class="svg-label"
                      text-anchor="middle"
                      data-for="left-circle"
                    >
                      <tspan fill="#e99275">Trust code not bankers</tspan>
                    </text>

                    <text
                      x="613.5"
                      y="461.5"
                      class="svg-label-mid"
                      text-anchor="middle"
                      data-for="right-circle"
                    >
                      DeSci
                    </text>
                    <text
                      x="750"
                      y="635"
                      class="svg-label"
                      text-anchor="middle"
                      data-for="right-circle"
                    >
                      <tspan fill="#aed2d7">Every disease is curable</tspan>
                    </text>
                  </svg>
                </div>
              }
            </div>
            <h4 className="mt-0 mb-0 text-center sub-header tam-bleed">
              our tam is bigger than yours
            </h4>
            <div className="contact-us-margin">
              <Link to="/contact">
                <div className="mt-10 cta-button-wrap-outer">
                  <div className="cta-button-wrap">
                    <button className="cta-button">GET IN TOUCH</button>
                  </div>
                </div>
              </Link>
            </div>

            <p className="rights-text">
              All Rights Reserved. Not a Public Solicitation.
            </p>
          </div>
        </motion.div>
        {/* <img
        src={waves}
        alt="waves"
        className="waves"
    
      /> */}
      </motion.div>
    </div>
  );
}
