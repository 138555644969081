import { React } from "react";
import footerlogo from "./assets/footlogo.png";
const Footerabout = () => {
  return (
    <div className="footer">
      <div className="footer-logo">
        <img src={footerlogo} />
      </div>
      <h5 className="footer-brandtext">ID Theory</h5>
      <p className="text-center footer-text">
        All Rights Reserved. Not a Public Solicitation. These statements do not
        constitute investment advice and are for informational purposes only to
        illustrate an approach concerning certain investment strategies employed
        on behalf of the Fund. The persons identified herein have not been
        compensated by ID Theory, the Investment Manager or any affiliate.
      </p>
    </div>
  );
};

export default Footerabout;
