export const svgVariants = {
  initial: {  pathLength: 0 },
  visible: {
    
    pathLength: 1,
    transition: { duration: 2.5 },
  },
  visiblestrat: {
    pathLength: 1,
    transition: { duration: 2.5 },
  },
};

export const pageVariants = {
  initial: {
    transition: { duration: 0.25 },
    opacity: 0,
    scrollXProgress: 1,
  },
  in: {
    transition: { duration: 0.25 },
    opacity: 1,
    scrollXProgress: 1,
  },
  out: {
    transition: { duration: 0.25 },
    opacity: 0,
  },
};

export const bgVariants = {
  initial: {
    transition: { duration: 0.5 },
    opacity: 0,
  },
  in: {
    transition: { delay: 0.85, duration: 1.5 },
    opacity: 0.25,
  },
  out: {
    transition: { duration: 0.1 },
    opacity: 0,
  },
};

export const transitionVariants = {
    initial: {
        transition: { duration: 0.75 },
        x: "-100%",
      },
      in: {
        transition: { duration: 0.75, ease: "easeOut" },
        x: 0,
      },
      out: {
        transition: { duration: 0.25, ease: "easeIn" },
        x: "-100%",
      },
};

export const orbit = {
  initial: {
    rotate: 0
  },
  in: {
    rotate: 180
  },
  out: {
    rotate: 0
  }
}

export const heartAnim = {
  
  duration: 2.5,
  initial: {  duration: 2.5, strokeWidth: 1.5, repeat: Infinity },
  visible: {
    strokeWidth: 1,
    transition: { duration: 1.5, repeat: Infinity },
    
  },
 
};