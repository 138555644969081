import { React } from "react";

import footerlogo from "./assets/footlogo.png";
const Footerportfolio = () => {
  return (
    <div className="footer">
      <div className="footer-logo">
        <img src={footerlogo} />
      </div>
      <h5 className="footer-brandtext">ID Theory</h5>
      <p className="text-center footer-text">
        All Rights Reserved. Not a Public Solicitation. The investments or
        portfolio companies mentioned or described on this page do not represent
        all assets. There can be no assurance that the investments will be
        profitable or that other investments made in the future will have
        similar characteristics or results.
      </p>
    </div>
  );
};

export default Footerportfolio;
