import React, { useState } from "react";
import { motion } from "framer-motion";

import NavbarPages from "../NavbarPages";
import { svgVariants, pageVariants } from "../animations";
import ScrollToTopOnMount from "./ScrollToTopOnMount";
import ParticlesBackground from "./particlesBackground";
import { Helmet } from "react-helmet";
import emailjs from "emailjs-com";
import Footercontact from "../Footercontact";

export default function Contact() {
  const [message, setMessage] = useState("");

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm("gmail", "idtmail", e.target, "user_24Vqfg8WcYmCt3jUOOmN0")
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    setMessage(`Thanks for your message. We'll get back to you soon.`);
  }
  return (
    <motion.div>
      <Helmet>
        <title>ID Theory || Contact</title>
        <meta
          name="description"
          content="Please get in touch with any general queries."
        />
      </Helmet>
      <ParticlesBackground />
      <ScrollToTopOnMount />
      <NavbarPages />
      <motion.div
        className="contact"
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
      >
        <motion.div
          className="vms wrapper px-10 mx-auto"
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariants}
        >
          <div className=" lg:mb-60 md:mb-40 sm:mb-2 contact-label0 mb-10">
            <h2 className="contact-header mb-20 text-center">CONTACT US</h2>
            <div className="ac-c-wrapper">
              <div className="ac-c-blurb">
                <h4 className="text-center contact-blurb">
                  If you're a project founder building with aligned interests or
                  an investor interested to hear more about ID Theory, we would
                  be happy to schedule a call.
                </h4>
              </div>
              <div className="ac-c-form">
                <form onSubmit={sendEmail}>
                  <label className="mb-1 contact-label block"> Name </label>
                  <input
                    className="mb-6 w-full block"
                    type="text"
                    name="name"
                    required
                  />
                  <h6 className="mb-2 contact-label text-left">
                    SELF QUALIFICATION
                  </h6>
                  <div className="contact-options mb-6">
                    <div>
                      <input
                        type="checkbox"
                        id="option1"
                        name="projectfounder"
                        value="Project Founder"
                      />
                      <label className="option-font" htmlFor="option1">
                        {" "}
                        Project Founder{" "}
                      </label>
                    </div>

                    <div>
                      <input
                        type="checkbox"
                        id="option2"
                        name="sophisticatedinvestor"
                        value="Sophisticated Investor"
                      />
                      <label className="option-font" htmlFor="option2">
                        {" "}
                        Sophisticated Investor{" "}
                      </label>
                    </div>

                    <div>
                      <input
                        className=""
                        type="checkbox"
                        id="option3"
                        name="highnetworth"
                        value="High Net-worth Individual"
                      />
                      <label className="option-font" htmlFor="option3">
                        {" "}
                        High Net-worth Individual{" "}
                      </label>
                    </div>
                    <div>
                      <input
                        className=""
                        type="checkbox"
                        id="option4"
                        name="na"
                        value="none"
                      />
                      <label className="option-font" htmlFor="option4">
                        {" "}
                        Other
                      </label>
                    </div>
                  </div>

                  <label className="mb-1 contact-label block">Email</label>
                  <input
                    className="mb-6 w-full block"
                    type="email"
                    name="emailaddress"
                    required
                  />
                  <label className="mb-1 contact-label block">Subject</label>
                  <input
                    className="mb-6 w-full block"
                    type="text"
                    name="subject"
                    required
                  />
                  <label className="mb-1 contact-label block">Message</label>
                  <label className="mb-6 w-full">
                    <textarea
                      className="block mb-2 contact-label w-full message-area"
                      name="message"
                      id="message"
                      cols="30"
                      rows="3"
                      required
                    ></textarea>
                  </label>
                  {/* <input type="submit" value="Submit"> */}
                  <div className="submit-wrap">
                    <div className="thanks-message px-10">{message}</div>

                    <div>
                      {" "}
                      <button className="contact-submit">Send</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>
      <Footercontact />
    </motion.div>
  );
}
