import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import 'react-multi-carousel/lib/styles.css';
/* eslint-disable import/first */
if (typeof process === 'undefined') {
  global.process = {
    env: { DEBUG: undefined },
  };
}
/* eslint-enable import/first */
ReactDOM.render(
  <React.StrictMode>
    <Router id="root">
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root"),
  () => console.log('App has been rendered.')
);