import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import Footerstrat from "../Footerstrat";
import NavbarPages from "../NavbarPages";
import { bgVariants, pageVariants } from "../animations";
import ScrollToTopOnMount from "./ScrollToTopOnMount";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { Helmet } from "react-helmet";
import { getContentfulData } from "../hook/getContentfulData";

export default function Overview() {
  const [data, setData] = useState();

  const query = `{
    strategyPageCollection(limit: 1) {
      items {
        strategyDescription {
          json
          links {
            assets {
              block {
                sys {
                  id
                }
                url
              }
            }
            entries {
              inline {
                sys {
                  id
                }
              }
              hyperlink {
                sys {
                  id
                }
              }
              block {
                sys {
                  id
                }
              }
            }
          }
        }


        investmentsAreasCollection{
          items{
            title
            image{
              url
            }
            description
          }
        }

        legallyWrappedDaOsShortLine
        legallyWrappedDaOsCollection{
          items{
            image{
              url
            }
            title
            description
          }
        }

        detailDescription{
          json
          links{
            assets{
              block{
                sys{
                  id
                }
                url
                title
              }
            }
            entries{
              inline{
                sys{
                  id
                }
              }
            }
          }
        }

      }
    }
  }
  `;

  const handleGetStrategy = async () => {
    const res = await getContentfulData(query);
    const {
      data: {
        strategyPageCollection: { items },
      },
    } = res;
    setData({
      description: items[0]?.strategyDescription,
      investmentAreas:
        items[0]?.investmentsAreasCollection?.items.length > 0
          ? items[0]?.investmentsAreasCollection?.items
          : [],
      shortDesc: items[0]?.legallyWrappedDaOsShortLine,
      daosBox:
        items[0]?.legallyWrappedDaOsCollection?.items.length > 0
          ? items[0]?.legallyWrappedDaOsCollection?.items
          : [],
      detail_desc: items[0]?.detailDescription,
    });
  };
  useEffect(() => {
    handleGetStrategy();
  }, []);

  function renderOptions(links) {
    if (links) {
      // create an asset map
      const assetMap = new Map();
      // loop through the assets and add them to the map
      for (const asset of links.assets.block) {
        assetMap.set(asset.sys.id, asset);
      }

      // create an entry map
      const entryMap = new Map();
      // loop through the inline linked entries and add them to the map
      for (const entry of links.entries.inline) {
        entryMap.set(entry.sys.id, entry);
      }

      return {
        renderMark: {
          [MARKS.BOLD]: (text) => {
            return <b style={{ color: "black", fontWeight: "bold" }}>{text}</b>;
          },
        },
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: (node, next) => {
            // find the asset in the assetMap by ID
            const asset = assetMap.get(node.data.target.sys.id);
            // render the asset accordingly
            return (
              <div className="antenna-image">
                <img src={asset.url} alt={`${asset}`} />
              </div>
            );
          },
          [INLINES.HYPERLINK]: (node, children) => {
            return (
              <a
                href={node.data.uri}
                style={{
                  color: "blue",
                  justifyItems: "center",
                  alignItems: "center",
                }}
              >
                {children}
              </a>
            );
          },
          [INLINES.EMBEDDED_ENTRY]: (node, children) => {
            // find the entry in the entryMap by ID
            return <div>{children}</div>;
          },
          [BLOCKS.UL_LIST]: (node, children) => (
            <ul style={{ listStyle: "disc", fontFamily: "Futura PT Book" }}>
              {children}
            </ul>
          ),
          [BLOCKS.OL_LIST]: (node, children) => (
            <ol style={{ listStyle: "decimal", fontFamily: "Futura PT Book" }}>
              {children}
            </ol>
          ),
          [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
            const entry = entryMap.get(node.data.target.sys.id);
            return (
              <div
                style={{ listStyle: "decimal", fontFamily: "Futura PT Book" }}
              >
                {children}
              </div>
            );
          },
        },
      };
    }
  }
  const customOrder = [3, 0, 2, 1];
  return (
    
    <motion.div>
      <Helmet>
        <title>ID Theory || Overview</title>
        <meta
          name="description"
          content="ID Theory's portfolio construction optimises exposure according to market cycles whilst differentiating themes of cryptoassets and decentralised networks.ID Theory applies principled and innovative investment strategies, realising the full opportunities in this emerging asset class."
        />
      </Helmet>

      <ScrollToTopOnMount />
      <motion.div
        className="vms-strategy-bg"
        initial="initial"
        animate="in"
        exit="out"
        variants={bgVariants}
      ></motion.div>
      <NavbarPages />
      <motion.div
        className="vms wrapper  px-10 mx-auto"
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
      >
        <div className="lg:mb-40 md:mb-40 sm:mb-10 mb-10">
          <div className="vms-header">
            <h2 className="vms-header-strategy">Overview</h2>
          </div>
          <div className="vms-summary">
            {data?.description?.links &&
              documentToReactComponents(
                data?.description?.json,
                renderOptions(data?.description?.links)
              )}
            <div className="mb-5 md:mt-10 mt-20">
              <h3 className="mobile-sub-head">Core Investment Areas</h3>
              <div className="investment-grid-wrapper">
              <div className="investment-grid">



{data?.investmentAreas?.length > 0 &&
  customOrder.map((customIndex, displayIndex) => {
    const item = data.investmentAreas[customIndex];

    return (
      <a 
        key={`investment-link-${customIndex}`} 
        href={customIndex === 0 ? "https://medium.com/id-theory/decentralisation-is-disrupting-drug-development-28b5ba5d447f" : 
              customIndex === 1 ? "https://medium.com/id-theory/pureplay-on-chain-games-74169a38484a" : 
              customIndex === 2 ? "https://medium.com/id-theory/navigating-the-digital-frontier-as-blockchain-and-ai-collide-e0bf109ac6d7" : 
              "https://medium.com/id-theory/asserting-algorithmic-independence-in-defi-3-0-d489702afd0a"}
        target="_blank" // Opens link in a new tab
        rel="noopener noreferrer" // For security reasons
      >
        <div className={`investment-grid-item igi-${displayIndex + 1}`}>
          <div className="inline investment-card-head">
            <img src={item?.image?.url} className="mr-2" />
            <h5 className="investment-headers">{item?.title}</h5>
          </div>
        </div>
      </a>
    );
  })}


</div>

              </div>
            </div>

         
          </div>
        </div>
      </motion.div>
      <Footerstrat />
    </motion.div>
  );
}
