import { React } from "react";

import footerlogo from "./assets/footlogo.png";
const Footerstrat = () => {
  return (
    <div className="footer">
      <div className="footer-logo">
        <img src={footerlogo} />
      </div>
      <h5 className="footer-brandtext">ID Theory</h5>
      <p className="text-center footer-text">
        All Rights Reserved. Not a Public Solicitation. The description herein
        of the Investment Manager’s approach is based on current expectations.
        It should not be considered definitive or guaranteed that the strategies
        and investment portfolio will exist solely in these areas or be
        successful.
      </p>
    </div>
  );
};

export default Footerstrat;
