import React, { useEffect, useState } from "react";

import { motion } from "framer-motion";
import Footerportfolio from "../Footerportfolio";
import NavbarPages from "../NavbarPages";
import { bgVariants, pageVariants } from "../animations";
import ScrollToTopOnMount from "./ScrollToTopOnMount";

import Tab from "./Tab";

import { Helmet } from "react-helmet";
import { getContentfulData } from "../hook/getContentfulData";

export default function Portfolio() {
  return (
    <motion.div>
      <Helmet>
        <title>ID Theory || Portfolio</title>
        <meta
          name="description"
          content="ID Theory applies principled and innovative investment strategies, realising the full opportunities in this emerging asset class."
        />
      </Helmet>
      <ScrollToTopOnMount />
      <motion.div
        className="vms-vision-bg"
        initial="initial"
        animate="in"
        exit="out"
        variants={bgVariants}
      ></motion.div>
      <NavbarPages />
      <motion.div
        className="vms wrapper px-10 mx-auto"
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
      >
        <section className="">
          <div className="vms-header">
            <h2 className="vms-header-mission">Portfolio</h2>
            <h3 className="mobile-sub-head dir-invest-head">
              DIRECT INVESTMENTS
            </h3>
          </div>

          <div class="site-wrapper"></div>
          <Tab />
        </section>

        {/* <div className="vms-summary-blurb mt-40">
          <h5 className="text-center">Disclaimer</h5>
          <p className="text-center disclaimer-body">
            All Rights Reserved. Not a Public Solicitation. The description
            herein of the Investment Manager's approach is based on current
            expectations and should not be considered definitive or a guarantee
            that the strategies and investment portfolio will exist solely in
            these areas.
          </p>
        </div> */}

        {/* <div className="vms-trio">
          <div className="vms-trio-item">
            <img src={trusticon} alt="trust icon" className="vms-trio-icon" />
            <h4 className="vms-trio-header">disintermediated TRUST</h4>
            <ul>
              <li>
                Transact with anyone in a trusted, secure, and transparent
                manner.
              </li>
              <li>
                Developers build on top of protocols with zero platform risk.
              </li>
            </ul>
          </div>
          <div className="vms-trio-item">
            <img
              src={efficiencyicon}
              alt="efficiency icon"
              className="vms-trio-icon"
            />
            <h4 className="vms-trio-header">enhanced EFFICIENCY</h4>
            <ul>
              <li>
                Transact with anyone in a trusted, secure, and transparent
                manner.
              </li>
              <li>
                Developers build on top of protocols with zero platform risk.
              </li>
            </ul>
          </div>
          <div className="vms-trio-item">
            <img
              src={securityicon}
              alt="security icon"
              className="vms-trio-icon"
            />
            <h4 className="vms-trio-header">provable SECURITY</h4>
            <ul>
              <li>
                Transact with anyone in a trusted, secure, and transparent
                manner.
              </li>
              <li>
                Developers build on top of protocols with zero platform risk.
              </li>
            </ul>
          </div>
        </div> */}
      </motion.div>
      <Footerportfolio />
    </motion.div>
  );
}
