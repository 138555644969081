const particlesConfig = {
  particles: {
    number: {
      value: 82,
      density: {
        enable: true,
        value_area: 1525.908125981517
      }
    },
    color: {
      value: "#5e6c73"
    },
    shape: {
      type: "circle",
      stroke: {
        width: 1,
        color: "#5e6c73"
      },
      polygon: {
        nb_sides: 4
      },
      image: {
        src: "img/github.svg",
        width: 100,
        height: 100
      }
    },
    opacity: {
      value: 0.29198923585627795,
      random: false,
      anim: {
        enable: false,
        speed: 1,
        opacity_min: 0.1,
        sync: false
      }
    },
    size: {
      value: 2,
      random: true,
      anim: {
        enable: false,
        speed: 40,
        size_min: 0.1,
        sync: false
      }
    },
    line_linked: {
      enable: true,
      distance: 157.83201938177186,
      color: "#5e6c73",
      opacity: 0.3472304426398981,
      width: 0.6313280775270874
    },
    move: {
      enable: true,
      speed: 1,
      direction: "none",
      random: false,
      straight: false,
      out_mode: "bounce",
      bounce: false,
      attract: {
        enable: false,
        rotateX: 946.9921162906311,
        rotateY: 1200
      }
    }
  },
  interactivity: {
    detect_on: "canvas",
    events: {
      onhover: {
        enable: false,
        mode: "repulse"
      },
      onclick: {
        enable: false,
        mode: "remove"
      },
      resize: false
    },
 
  },
  retina_detect: true
}


export default particlesConfig;