import React from "react";
// import trusticon from "./svgs/trust.svg";
// import efficiencyicon from "./svgs/efficiency.svg";
// import securityicon from "./svgs/security.svg";
import { motion } from "framer-motion";
import NavbarPages from "../NavbarPages";
import { svgVariants, pageVariants, bgVariants } from "../animations";
import ScrollToTopOnMount from "./ScrollToTopOnMount";
import { Link } from "react-router-dom";
import Heart from "./Heart";

import { Helmet } from "react-helmet";

export default function Vision() {
  return (
    <motion.div>
          <Helmet>
<title>ID Theory || Vision</title>
<meta 
      name="description"
      content="The founding team have a clear vision of where blockchain technology excels with a strategy that invests in industry verticals."
 />
 
      </Helmet>
      <ScrollToTopOnMount />
      <motion.div
        className="vms-vision-bg"
        initial="initial"
        animate="in"
        exit="out"
        variants={bgVariants}
      ></motion.div>

      <NavbarPages />
      <motion.div
        className="vms wrapper px-10 mx-auto"
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
      >
        <motion.svg
          id="vms-logo-test"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 64 64"
        >
          <motion.path
            id="vms-logo-test"
            data-name="Layer 2"
            d="M63,32A31,31,0,1,1,32,1,31,31,0,0,1,63,32ZM32,21A11,11,0,1,0,43,32,11,11,0,0,0,32,21Zm0,0s-9.5-.5-21,11C22.5,43.5,32,43,32,43m0,0s9.5.5,21-11C41.5,20.5,32,21,32,21m6,11a6,6,0,1,0-6,6V32ZM6,32A26,26,0,0,0,32,58M58,32A26,26,0,0,0,32,6M57,35h2M5,29H7"
            fill="none"
            stroke="#91ccd3"
            strokeMiterlimit="10"
            initial="initial"
            animate="visible"
            variants={svgVariants}
          />
        </motion.svg>

        <div className="mb-10">
          <div className="vms-header">
            <h2 className="vms-header-vision">Vision</h2>
          </div>

          <div className="vms-summary">
            <h4 className="lg:mb-20 md:mb-20 sm:mb-20 mb-20">
              Blockchain technology enables previously unseen coordination and
              cooperation via decentralised networks.
            </h4>

            <div class="heart-text-frame  lg:mb-20 md:mb-20 sm:mb-20 mb-20">
              <div className="">
           
              
                    <defs>
                      <linearGradient
                        id="gradient"
                        gradientTransform="rotate(90)"
                      >
                        <stop offset="10%" stopColor="#1c1d20" />
                        <stop offset="90%" stopColor="#1c1d20" />
                      </linearGradient>
                    </defs>
              
               
          
                
                    <defs>
                      <linearGradient
                        id="gradient"
                        gradientTransform="rotate(90)"
                        opacity="0.3"
                      >
                        <stop offset="10%" stopColor="#1c1d20" />
                        <stop offset="90%" stopColor="#1c1d20" />
                      </linearGradient>
                    </defs>
                
               
              </div>
              <div class=" heart-svg">
                <Heart />
              </div>
              <div class=" heart-text">
                <h4 className="">
                  Cryptoassets sit at the heart of these protocols and align the
                  interests and incentives of both users and suppliers of the
                  decentralised networks they power.
                </h4>
              </div>
            </div>

            <div className="lg:mt-20 md:mt-10 sm:mt-5 mt-2">
              <h4>
                In time this will lead to fully decentralised autonomous
                organisations (DAOs) which we regard as the evolution of the
                corporation.
              </h4>

              <h4>
                The world in which we live is being transformed by a digital
                revolution driven through the combination of open-source
                networks and cryptoasset powered economies. Our vision is clear,
                we forsee a world where:
              </h4>
            </div>
            <div className="vms-summary-blurb lg:mb-20 md:mb-10 sm:mb-5 mb-2">
              <ul className="vms-summary-blurb-list">
                <li>
                  Any individual agent or entity is able to interact and
                  directly transact with any other, in a trusted, secure, and
                  transparent manner.
                </li>
                <li>
                  Complete ownership of your data, who you share it with, and
                  how you monetise it; digital identification and authentication
                  permitting reliable reputations systems; financial sovereignty
                  and inclusiveness.
                </li>
                <li>
                  Developers build open-source applications on top of
                  open-source protocols without platform risk. This will lead to
                  an explosion of innovation.
                </li>
                <li>
                  Decentralised applications are composable and interoperable
                  allowing fully autonomous decentralised organisations.
                  Complete transparency and accountability will discourages bad
                  behaviour and reduces malicious intent.
                </li>
                <li>
                  The Metaverse is a collective virtual shared space, created by
                  the convergence of augmented reality and persistent virtual
                  space, powered by blockchain. It will allow anyone to create
                  economic value wherever they may be.
                </li>
                <li>
                  Decentralised networks are meritocracies that encourage
                  contribution as the basis for advancement, rather than
                  connections, status, or power. The cryptoassets are the reward
                  to incentivise participation and generate value.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="epilogue">
          <h4>
            This innovation drastically shifts the power dynamics of our society
            and bring about unimaginable efficiencies for the global economy. It
            is our
            <Link to="/mission">
              <span className="vms-button-wrap vms-button-wrap-m">
                <button className="vms-button vms-button-m">MISSION</button>
              </span>
            </Link>
            to remain agile and responsive to developments in the ecosystem.
          </h4>
        </div>

        {/* <div className="vms-trio">
          <div className="vms-trio-item">
            <img src={trusticon} alt="trust icon" className="vms-trio-icon" />
            <h4 className="vms-trio-header">disintermediated TRUST</h4>
            <ul>
              <li>
                Transact with anyone in a trusted, secure, and transparent
                manner.
              </li>
              <li>
                Developers build on top of protocols with zero platform risk.
              </li>
            </ul>
          </div>
          <div className="vms-trio-item">
            <img
              src={efficiencyicon}
              alt="efficiency icon"
              className="vms-trio-icon"
            />
            <h4 className="vms-trio-header">enhanced EFFICIENCY</h4>
            <ul>
              <li>
                Transact with anyone in a trusted, secure, and transparent
                manner.
              </li>
              <li>
                Developers build on top of protocols with zero platform risk.
              </li>
            </ul>
          </div>
          <div className="vms-trio-item">
            <img
              src={securityicon}
              alt="security icon"
              className="vms-trio-icon"
            />
            <h4 className="vms-trio-header">provable SECURITY</h4>
            <ul>
              <li>
                Transact with anyone in a trusted, secure, and transparent
                manner.
              </li>
              <li>
                Developers build on top of protocols with zero platform risk.
              </li>
            </ul>
          </div>
        </div> */}
      </motion.div>
      {/* <div className="vms-skew">
        <div className="wrapper px-10 mx-auto">
          <h3 className="vms-skew-header">
            Innovative Exposure: Deploying capital into decentralised networks.
          </h3>
          <p>
            ID Theory believes that the majority of the value created will
            accrue to the following themes of cryptoassets. This value capture
            is not mutually exclusive to any of these categories and we allocate
            accordingly.
          </p>
          <div className="vms-skew-trio">
            <div className="vms-skew-trio-item">
              <h4>Currencies</h4>
              <div className="vms-pie">
                <svg
                  id="Layer_1"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 100 100"
                >
                  <polygon
                    points="80.59 85.3 14.71 85.3 14.71 19.41 14.71 85.3 80.59 85.3"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    d="M10,75.88h0Z"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    d="M10,66.47h0Z"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    d="M10,57.06h0Z"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    d="M10,47.65h0Z"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    d="M10,38.24h0Z"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    d="M10,28.82h0Z"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    d="M24.12,90v0Z"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    d="M33.53,90v0Z"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    d="M42.94,90v0Z"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    d="M52.35,90v0Z"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    d="M61.76,90v0Z"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    d="M71.18,90v0Z"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <polygon
                    points="19.41 19.41 14.71 10 10 19.41 19.41 19.41"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <polygon
                    points="80.59 90 90 85.3 80.59 80.59 80.59 90"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <rect
                    x="14.71"
                    y="28.82"
                    width="28.23"
                    height="9.41"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <rect
                    x="42.94"
                    y="28.82"
                    width="18.82"
                    height="9.41"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <rect
                    x="14.71"
                    y="47.65"
                    width="37.65"
                    height="9.42"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <rect
                    x="52.35"
                    y="47.65"
                    width="28.23"
                    height="9.42"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <rect
                    x="14.71"
                    y="66.47"
                    width="28.23"
                    height="9.41"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <rect
                    x="42.94"
                    y="66.47"
                    width="9.41"
                    height="9.41"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </svg>
              </div>
            </div>
            <div className="vms-skew-trio-item">
              <h4>Currencies</h4>
              <div className="vms-pie">
                <svg
                  id="Layer_1"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 100 100"
                >
                  <path
                    d="M77.09,18.85,56.25,39.69V10.22A44.72,44.72,0,0,1,77.09,18.85Z"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    d="M74.75,30,50,54.78v-35h0A35,35,0,1,0,74.75,30Z"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </svg>
              </div>
            </div>
            <div className="vms-skew-trio-item">
              <h4>Currencies</h4>
              <div className="vms-pie">
                <svg
                  id="Layer_1"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 100 100"
                >
                  <circle
                    cx="23.33"
                    cy="40"
                    r="4.44"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <circle
                    cx="41.11"
                    cy="57.78"
                    r="4.44"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <circle
                    cx="58.89"
                    cy="31.11"
                    r="4.44"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    d="M81.11,57.78a4.45,4.45,0,1,1-4.44-4.45A4.44,4.44,0,0,1,81.11,57.78Z"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    d="M74.2,54.08,61.35,34.81Z"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    d="M38,54.64l-11.5-11.5Z"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    d="M56.42,34.81,43.58,54.08Z"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    d="M79.13,54.08,90,37.78Z"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    d="M20.87,43.7,10,60Z"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <rect
                    x="10"
                    y="20"
                    width="80"
                    height="60"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    d="M23.33,44.44v0Z"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    d="M41.11,62.22v0Z"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    d="M58.89,35.56v0Z"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    d="M76.67,62.22v0Z"
                    fill="none"
                    stroke="#c4c1bc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </motion.div>
  );
}
